.box-notification-dot {
	@apply relative;
}
.box-notification-dot:after {
	content: '';
	position: absolute;
	top: -4px;
	right: -4px;
	height: 10px;
	width: 10px;
	background-color: #f44a77;
	border-radius: 50%;
	outline: 2px solid white;
}
.box-disabled {
	opacity: 60%;
}
