table.result-table {
	width: 100%;
	height: 100%;
	box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.08);
	border: 1px solid #e8e8e8;
	border-radius: 10px;
	border-spacing: 0;
	border-collapse: separate;
	overflow: hidden;
}
table.result-table td {
	border-top: 1px solid #e8e8e8;
}
table.result-table thead {
	height: 28px;
	background: #f3f5f8;
	border: none;
}
table.result-table thead th {
	font-size: 12px;
	line-height: 16px;
	color: #a2a2a2;
	text-align: left;
	padding: 6px 10px;
	border: none;
}
table.result-table tr {
	height: 28px;
}
table.result-table tr td {
	padding: 6px 10px;
	border: none;
	font-size: 14px;
	line-height: 20px;
	color: #454545;
}
