.client-select-product {
	@apply md:container mx-auto;
	max-width: calc(100% - 280px);
	@media (min-width: 375px) and (max-width: 1000px) {
		max-width: 100%;
	}
	@media (min-width: 1280px) {
		max-width: 930px;
	}
	@media (min-width: 1536px) {
		max-width: 1132px;
	}
	.search-group {
		position: relative;
		height: 48px;
	}
	.search-input {
		@apply block py-2 px-3 border-[1px] border-gray-22 rounded-lg placeholder:text-gray-22 outline-none flex-1;
	}

	.search-button {
		@apply bg-primary-violet text-white rounded-lg py-1.25 px-3 justify-center;
		position: absolute;
		right: 12px;
		z-index: 2;
		top: 8px;
		height: fit-content;
	}

	.flag {
		@apply inline-block mx-5 rounded overflow-hidden h-[16px] translate-y-0.5;
	}

	.close {
		@apply rounded-bl-md p-[7px] border-[1px] border-gray-10 hover:border-primary-violet;
	}

	.product-image {
		@apply bg-cover bg-center rounded-lg;
		width: 62px;
		height: 62px;
		&.cover {
			width: 100%;
			height: 345px;
		}
	}

	.order-now-btn {
		@apply flex items-center rounded-7 cursor-pointer w-full px-3 border-0 bg-transparent hover:bg-gray-10 hover:bg-opacity-50 bg-gradient-primary text-white hover:text-white justify-center;
		height: 48px;
	}
}
