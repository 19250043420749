.invoices-table table {
	@apply w-full h-full rounded-[0px] border-collapse border-hidden;
	box-shadow: 0 0 0 1px #e8e8e8;
}

.invoices-table td {
	@apply font-medium border border-solid border-gray-10 h-7 p-0;
}

.invoices-table table th {
	@apply bg-gray-1;
}

.invoices-table thead > tr > th {
	@apply font-medium text-base text-gray-80 bg-gray-1 border border-solid border-gray-10 h-7 px-6 py-3;
}

.invoices-table tbody > tr:hover {
	box-shadow: 0 0 0 1px #a2a2a2;
}

.invoices-table-row-selected {
	@apply bg-gray-10-opacity-50;
}

.invoices-table .rc-table-content {
	@apply h-full;
}

.invoices-table .rc-table-container {
	@apply h-full;
}

.invoices-table .rc-table-row:hover {
	cursor: pointer;
}

.table-payment table {
	@apply w-full;
	box-shadow: none;
}

.invoices-table-row-overdue {
	@apply bg-lavender-pinkapprox-60;
}

.invoices-table-row-pending {
	@apply bg-seashell-peach;
}
