.user-table table {
	@apply min-w-full h-full border-collapse border-hidden cursor-pointer;
}

.user-table thead > tr > th:first-child {
	@apply rounded-l-lg;
}

.user-table thead > tr > th:last-child {
	@apply rounded-r-lg;
}

.user-table thead > tr > th {
	@apply font-medium h-12.25 bg-gray-10;
}

.user-table tbody > tr > td {
	@apply font-medium;
}

.user-table tbody > tr:first-child {
	@apply border-t-0;
}

.user-table tbody > tr {
	@apply w-full relative border-t border-gray-10;
	border-top-style: solid;
}

.user-table tbody > tr.rc-table-row:hover {
	@apply border border-solid border-gray-40 mt-5 rounded-10;
}

.user-table tbody > tr:first-child:hover {
	@apply border-t-0;
}
.user-table-row-selected {
	@apply bg-gray-10-opacity-50;
}
