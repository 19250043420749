.payment-table table {
	@apply w-full h-full border-collapse;
	border-top-style: hidden;
	border-right-style: hidden;
	border-left-style: hidden;
}

.payment-table td {
	@apply font-medium border border-solid border-gray-10 p-0;
}

.payment-table thead > tr > th:last-child {
	@apply rounded-tr-10;
}

.payment-table thead > tr > td:first-child {
	@apply bg-gray-1 rounded-tl-10;
}

.payment-table thead > tr > td:last-child {
	@apply bg-gray-1;
}

.payment-table thead > tr > th {
	@apply font-medium text-sm text-gray-40 bg-gray-1 border border-solid border-gray-10 h-7 px-3 py-2;
}

.payment-table
	tbody
	> tr:hover:not(.rc-table-expanded-row-level-1):not(.rc-table-placeholder):not(
		.table-payment tbody > tr
	) {
	@apply rounded-10 outline outline-[1px] outline-primary-violet -outline-offset-1;
}

.payment-table-row-selected {
	@apply bg-gray-10-opacity-50;
}

.payment-table .rc-table-content {
	@apply h-full;
}

.payment-table .rc-table-container {
	@apply h-full;
}

.payment-table .rc-table-row-expand-icon-cell {
	@apply w-[56px] text-center;
}

/* Expanded table styles */

.table-payment table {
	@apply w-full;
	box-shadow: none;
}

.table-payment tbody > tr:first-child {
	@apply h-[44px];
}

.table-payment tbody > tr:first-child > td {
	@apply bg-gray-1;
}

.table-payment tbody > tr:not(:first-child) > td {
	@apply h-[48px] bg-alabaster;
}

.table-payment tbody > tr:last-child > td:first-child {
	@apply rounded-bl-10;
}

.table-payment tbody > tr:last-child > td:last-child {
	@apply rounded-br-10;
}

.table-payment tbody > tr:first-child > td:not(:last-child) {
	@apply w-36;
}

.table-payment tbody > tr:last-child > td {
	@apply border-b-0;
}

.table-payment-admin tbody > tr:first-child > td:nth-child(4) {
	@apply w-[891px];
}

/* Buyer related styles */

.payment-table-buyer tbody > tr > td:nth-child(7) {
	@apply bg-floral-white;
}

.payment-table-buyer tbody > tr > td:nth-child(8) {
	@apply bg-black-squeeze;
}

/* Producer related styles */

.payment-table-producer tbody > tr > td:nth-child(4) {
	@apply bg-floral-white;
}

.payment-table-withdraw tbody > tr > td:nth-child(2) {
	@apply w-[56px];
}

.payment-table-withdraw tbody > tr > td:nth-child(3) {
	@apply w-[131px];
}

.payment-table-withdraw tbody > tr > td:nth-child(4):not(.table-payment td) {
	@apply w-[151px] bg-floral-white;
}

.payment-table-withdraw tbody > tr > td:nth-child(5) {
	@apply w-[109px];
}

/* Carrier related styles */

.payment-table-transporter tbody > tr > td:nth-child(3) {
	@apply bg-floral-white;
}

/* Admin related styles */

.payment-table-admin tbody > tr > td:nth-child(7) {
	@apply bg-floral-white;
}
