.input-group {
	@apply relative w-full;
}
.input-group svg {
	@apply absolute left-0 top-0 p-2.5;
}
.input-group input {
	@apply w-full box-border text-base leading-[22px] pl-[34px] py-1.5 pr-2.5 rounded-[8px] outline-none font-normal;
	background-color: rgb(255, 255, 255);
	border-width: 1px;
	border-color: rgb(209, 209, 209);
	border-style: solid;
	color: rgb(34, 34, 52);
}
.input-group input::placeholder {
	@apply text-gray-40 opacity-100;
}
