.modal-remove-truck-border {
	@apply border-t border-b border-t-gray-10 border-b-gray-10;
	border-top-style: solid;
	border-bottom-style: solid;
}

.modal-confirm-button,
.modal-reject-button {
	border-radius: 10px;
	@apply flex justify-center w-[165px];
}

.modal-confirm-button {
	@apply border-mountain-meadow;
}

.modal-reject-button {
	@apply border-red;
}

.custom-styles {
	--ReactInputVerificationCode-itemWidth: 48px;
	--ReactInputVerificationCode-itemHeight: 48px;
}

.custom-styles .ReactInputVerificationCode__container {
	gap: 15px;
}

.code-disabled {
	pointer-events: none;
}
.custom-styles .ReactInputVerificationCode__item {
	@apply flex items-center justify-center text-primary-dark-gray outline outline-1 outline-gray-10 rounded-10 bg-white;
	font-size: 32px;
	line-height: 40px;
	font-weight: 500;
	background-clip: padding-box;
}
