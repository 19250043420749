.scrollbar-custom {
	scrollbar-width: thin;
	scrollbar-color: #d1d1d1 transparent;
}

.scrollbar-custom::-webkit-scrollbar {
	height: 6px;
	width: 6px;
	background-color: transparent;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
	background-color: #e2e8f0;
	border-radius: 20px;
}

.scrollbar-custom::-webkit-scrollbar-track {
	background-color: transparent;
	border-radius: 20px;
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
	background-color: #cbd5e1;
}

.scrollbar-custom::-webkit-scrollbar-thumb:vertical {
	min-height: 30px;
}

.scrollbar-custom::-webkit-scrollbar-thumb:horizontal {
	min-width: 30px;
}
