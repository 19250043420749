.input-container,
.input-container-error {
	@apply relative px-0;
}

.input-container input,
.input-container-error input {
	@apply w-full py-2.5 border-none outline-none text-2xl leading-7 font-medium text-gray-80;
	border-bottom: 1px solid #d1d1d1;
}

.input-container input:hover {
	@apply border-b-gray-40;
}

.input-container-error input {
	@apply text-red;
}
.input-container input::placeholder {
	color: #d1d1d1;
	opacity: 1;
}

.input-container input:hover::placeholder {
	color: #a2a2a2;
	opacity: 1;
}

.input-container-error input {
	@apply border-b-red;
}
.input-container input:not(:placeholder-shown),
.input-container input:focus {
	@apply border-b-gray-80;
}

.input-container .input-currency,
.input-container-error .input-currency {
	@apply text-gray-80 absolute right-0 top-2.5;
}
