.modal-login-border {
	@apply border-t border-b border-t-gray-10 border-b-gray-10;
	border-top-style: solid;
	border-bottom-style: solid;
}

.modal-login-button {
	padding: 11px 77px;
	border-radius: 10px;
	@apply flex bg-button-primary-gradient-hover justify-center w-fit;
}
