.user-card-hide {
	overflow: hidden;
	height: 0;
}

.user-card-show {
	height: fit-content;
}
.user-card-border {
	@apply border-b border-t border-b-gray-10 border-t-gray-10;
	border-bottom-style: solid;
	border-top-style: solid;
}
