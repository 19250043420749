.company-information-editable-border {
	@apply border-b border-t border-b-gray-10 border-t-gray-10;
	border-bottom-style: solid;
	border-top-style: solid;
}

.company-information-editable-border-bottom {
	@apply border-b border-b-gray-10;
	border-bottom-style: solid;
}
