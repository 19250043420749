.order-table table {
	@apply w-full h-full border-collapse;
	border-top-style: hidden;
	border-right-style: hidden;
	border-left-style: hidden;
}

.order-table td {
	@apply font-medium border border-solid border-gray-10 p-3;
}

.order-table td:first-child {
	@apply px-1;
}

.order-table thead > tr > th:first-child {
	@apply rounded-tl-10;
}

.order-table thead > tr > th:last-child {
	@apply rounded-tr-10;
}

.order-table thead > tr > td:first-child {
	@apply bg-gray-1 rounded-tl-10;
}

.order-table thead > tr > th {
	@apply font-medium text-base text-gray-40 bg-gray-1 border border-solid border-gray-10 h-7 p-3;
}

.order-table tbody > tr:hover:not(.rc-table-placeholder) {
	@apply rounded-10 outline outline-[1px] outline-primary-violet -outline-offset-1;
}

.order-table-row-selected {
	@apply bg-gray-10-opacity-50;
}

.order-table .rc-table-content {
	@apply h-full;
}

.order-table .rc-table-container {
	@apply h-full;
}

.order-table .rc-table-row:hover {
	cursor: pointer;
}
