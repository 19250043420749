.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.order-table {
	min-width: 1000px;
}

.main-container {
	min-width: 920px;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.choices__item.choices__item--selectable {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.is-open .choices__list--dropdown,
.is-open .choices__list[aria-expanded] {
	background-color: white;
}

.input-error {
	color: rgb(247, 72, 39);
	font-size: 12px;
	line-height: 16px;
	padding-top: 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.modal-inside {
	@apply absolute bg-white border border-solid border-gray-10 rounded-xl;
}

.modal-inside-image {
	@apply absolute rounded-10;
}

.modal-overlay {
	@apply fixed flex items-center justify-center top-0 left-0 right-0 bottom-0 z-[1500] backdrop-blur;
	background: rgba(105, 105, 105, 0.6);
}

.modal-overlay-without-bg {
	@apply fixed flex items-center justify-center top-0 left-0 right-0 bottom-0 z-[500];
}

.button-green {
	@apply bg-mountain-meadow text-white transition hover:bg-mountain-meadow/80 hover:text-white;
}

.button-red {
	@apply bg-red text-white hover:text-white;
}

.leaflet-right {
	display: none !important;
}

/* WebKit-based browsers */
textarea::-webkit-scrollbar {
	width: 10px;
}

textarea::-webkit-scrollbar-track {
	background: transparent;
	border-radius: 10px;
}

textarea::-webkit-scrollbar-thumb {
	background-color: #b0b0b0;
	border-radius: 10px;
	border: 2px solid transparent;
}

/* Firefox */
textarea {
	scrollbar-width: thin;
	scrollbar-color: #b0b0b0 transparent;
}

/* WebKit-based browsers */
.sypac-scrollbar {
	overflow-y: scroll;
}

.sypac-scrollbar::-webkit-scrollbar {
	width: 10px;
}

.sypac-scrollbar::-webkit-scrollbar-thumb {
	background-color: #b0b0b0;
	border-radius: 10px;
	border: 2px solid transparent;
}

.sypac-scrollbar::-webkit-scrollbar-track {
	background: transparent;
	border-radius: 10px;
}

.sypac-scrollbar::-webkit-scrollbar-thumb {
	background-color: #b0b0b0;
	border-radius: 10px;
	border: 2px solid transparent;
}

/* Firefox */
.sypac-scrollbar {
	scrollbar-width: thin;
	scrollbar-color: #b0b0b0 transparent;
}
