.confirmation-custom-styles {
	--ReactInputVerificationCode-itemWidth: 48px;
	--ReactInputVerificationCode-itemHeight: 48px;
}

.confirmation-custom-styles .ReactInputVerificationCode__container {
	gap: 15px;
}

.confirmation-custom-styles .ReactInputVerificationCode__item {
	@apply flex items-center justify-center outline outline-1 outline-gray-10 rounded-10 bg-white text-gray-10;
	font-size: 32px;
	line-height: 40px;
	font-weight: 500;
	background-clip: padding-box;
	box-shadow: none;
}

.code-error .ReactInputVerificationCode__item {
	@apply text-red outline-red;
}
