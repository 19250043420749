.modal-delete-document-border {
	@apply border-t border-b border-t-gray-10 border-b-gray-10;
	border-top-style: solid;
	border-bottom-style: solid;
}

.modal-delete-document-confirm-button,
.modal-delete-document-reject-button {
	border-radius: 10px;
	@apply flex justify-center w-full;
}

.modal-delete-document-confirm-button {
	@apply border-mountain-meadow;
}

.modal-delete-document-reject-button {
	@apply border-red;
}
